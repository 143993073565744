import Api from '../../utils/api'
import Axios from '../../utils/request'

export default {
    namespace: 'true',
    state: {},
    mutations: {},
    actions: {
        // 获取项目设备统计数据
        getDashboardDevices(context: any, params: any) {
            return Axios.get(Api.dataReport.get.getDashboardDevices, { params })
        },
        // 获取能源统计面板数据
        getDashboardEnergy(context: any, params: any) {
            return Axios.get(Api.dataReport.get.getDashboardEnergy, { params })
        },
        // 仪表盘能耗数据接口
        dataIotdbStatistics(context: any, params: any) {
            return Axios.post(Api.dataReport.post.dataIotdbStatistics, params)
        },
        // 获取设备能源统计
        getDeviceEnergyDataByTime(context: any, params: any) {
            return Axios.post(Api.dataReport.post.getDeviceEnergyDataByTime, params)
        },
        // 获取故障设备统计面板数据
        getFaultDevice(context: any, params: any) {
            return Axios.get(Api.dataReport.get.getFaultDevice, { params })
        },
        // 获取节约能源数据仪表盘
        savingEnergy(context: any, params: any) {
            return Axios.get(Api.dataReport.get.savingEnergy, { params })
        },
    },
    getters: {},
}
