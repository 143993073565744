import Api from '../../utils/api'
import Axios from '../../utils/request'

export default {
    namespace: 'true',
    state: {},
    mutations: {},
    actions: {
        // 获取子账号列表
        getSubAccountPage(context: any, params: any) {
            return Axios.post(Api.userManager.post.getSubAccountPage, params)
        },
        // 获取子账号详情
        getAccountById(context: any, params: any) {
            return Axios.get(Api.userManager.get.getAccountById, { params })
        },
        // 删除账号
        deleteAccount(context: any, params: any) {
            return Axios.post(Api.userManager.post.deleteAccount, { params })
        },
        // 新增子账号
        addSubAccount(context: any, params: any) {
            return Axios.post(Api.userManager.post.addSubAccount, params)
        },
        // 编辑子账号
        editSubAccount(context: any, params: any) {
            return Axios.post(Api.userManager.post.editSubAccount, params)
        },
        // 变更子账号状态
        changeSubAccountStatus(context: any, params: any) {
            return Axios.post(Api.userManager.post.changeSubAccountStatus, params)
        },
        // 重置账号密码
        resetSubAccountPassword(context: any, params: any) {
            return Axios.post(Api.userManager.post.resetSubAccountPassword, params)
        },
        // 获取运营商列表
        getTenantPage(context: any, params: any) {
            return Axios.post(Api.userManager.post.getTenantPage, params)
        },
        // 获取运营商详情
        getTenantById(context: any, params: any) {
            return Axios.get(Api.userManager.get.getTenantById, { params })
        },
        // 新增运营商
        addTenant(context: any, params: any) {
            return Axios.post(Api.userManager.post.addTenant, params)
        },
        // 编辑运营商
        editTenant(context: any, params: any) {
            return Axios.post(Api.userManager.post.editTenant, params)
        },
        // 变更运营商状态
        changeTenantStatus(context: any, params: any) {
            return Axios.post(Api.userManager.post.changeTenantStatus, params)
        },
        // 重置运营商密码
        resetTenantPassword(context: any, params: any) {
            return Axios.post(Api.userManager.post.resetTenantPassword, params)
        },
        // 获取角色列表
        getRolePage(context: any, params: any) {
            return Axios.post(Api.userManager.post.getRolePage, params)
        },
        // 获取登录账户角色列表
        getAccountRoles(context: any, params: any) {
            return Axios.post(Api.userManager.post.getAccountRoles, params)
        },
        // 获取角色分层数据
        getRoleTree(context: any, params: any) {
            return Axios.post(Api.userManager.post.getRoleTree, params)
        },
        // 获取角色详情
        getRoleAuthById(context: any, params: any) {
            return Axios.get(Api.userManager.get.getRoleAuthById, { params })
        },
        // 变更角色状态
        changeRoleStatus(context: any, params: any) {
            return Axios.post(Api.userManager.post.changeRoleStatus, params)
        },
        // 新增角色
        addRole(context: any, params: any) {
            return Axios.post(Api.userManager.post.addRole, params)
        },
        // 编辑角色
        editRole(context: any, params: any) {
            return Axios.post(Api.userManager.post.editRole, params)
        },
        // 设置角色权限
        authRole(context: any, params: any) {
            return Axios.post(Api.userManager.post.authRole, params)
        },
    },
    getters: {},
}
