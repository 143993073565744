import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import routerList from '@/router/routerList'
import store from '@/store/index'
import { fetchDicts } from '@/utils/dicts'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: { name: 'Login' },
        meta: {
            title: '登录',
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/login/index.vue'),
        meta: {
            title: '登录',
        },
    },
    {
        path: '/dataStatistics',
        name: 'dataStatistics',
        component: () => import('../views/dataReport/dataStatistics/index.vue'),
        meta: {
            title: '数字化大屏',
            enTitle: 'dataStatistics',
        },
    },
    {
        path: '/newDataStatistics',
        name: 'NewDataStatistics',
        component: () => import('../views/dataReport/dataStatistics/index1.vue'),
        meta: {
            title: '大屏数据',
            enTitle: 'newDataStatistics',
        },
    },
    ...routerList,
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

// 全局前置路由守卫，进入路由前对登录态以及权限进行判断
router.beforeEach(async (to: any) => {
    const token = localStorage.getItem('ACCESS_TOKEN') || ''
    let userInfo: any = store.state.userInfo
    let localLang = localStorage.getItem('lang')
    const lang: any = store.state.lang
    if (!localLang) {
        localStorage.setItem('lang', 'zh-CN')
        localLang = 'zh-CN'
    }
    if (!lang) {
        await store.commit('setLang', localLang)
    }
    // token存在
    if (token) {
        // 用户信息存在
        if (Object.keys(userInfo).length) {
            if (to.name === 'Login') {
                return { name: 'TenantManager' }
            }
            // 判断是否有进入该菜单的权限
            if (to.meta.permissionCode && !userInfo.menusAuthCodes.includes(to.meta.permissionCode)) {
                const menuList: any = store.getters.menuList
                return { name: menuList[0].children[0].name }
            }
        } else {
            try {
                // 获取字典
                fetchDicts(store)
                await store.dispatch('getUserAuth')
                userInfo = store.state.userInfo
                // 判断是否有进入该菜单的权限
                if (to.meta.permissionCode && !userInfo.menusAuthCodes.includes(to.meta.permissionCode)) {
                    const menuList: any = store.getters.menuList
                    return { name: menuList[0].children[0].name }
                }
            } catch (e) {
                console.log(e)
                localStorage.removeItem('ACCESS_TOKEN')
                return { name: 'Login' }
            }
        }
    } else {
        if (to.name !== 'Login') {
            return { name: 'Login' }
        }
    }
})
export default router
